import React, { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { pushParams } from 'dpl/actions/query_params';
import Icon from 'dpl/common/components/Icon';
import {
  ABOUT_US_PATH,
  BREEDER_QUALITY_PATH,
  BREEDER_CENTER_PATH,
  COE_BREEDER_PATH,
  COE_PARENT_PATH,
  COE_SHELTER_PATH,
  HELP_CENTER_PATH,
  HELP_PATH,
  LOGIN_PATH,
  JOIN_AS_BREEDER_PATH,
  JOIN_AS_SHELTER_PATH,
  MISSION_PATH,
  PRIVACY_PATH,
  SHELTER_QUALITY_PATH,
  STANDARDS_PATH,
  TOS_PATH,
  HEALTH_TESTING_GUIDE_PATH,
  GOOD_DOG_POD_PATH,
  NONDISCRIMINATION_POLICY_PATH,
  BREED_DIRECTORY_PATH,
  PUPPY_DIRECTORY_PATH,
  BREEDER_DIRECTORY_PATH,
  LEARNING_CENTER_PATH
} from 'dpl/shared/constants/urls';
import MobileMenu from 'dpl/components/MobileMenu';

export const SECTIONS = [
  {
    name: 'about',
    title: 'About us',
    links: [
      {
        title: 'Our company',
        href: ABOUT_US_PATH
      },
      {
        title: 'Our mission',
        href: MISSION_PATH
      },
      {
        title: 'Non-discrimination',
        href: NONDISCRIMINATION_POLICY_PATH
      },
      {
        name: 'help center',
        title: 'Help Center',
        href: HELP_CENTER_PATH
      },
      {
        name: 'help',
        title: 'Contact us',
        href: HELP_PATH
      }
    ]
  },
  {
    name: 'breeders',
    title: 'For breeders',
    links: [
      {
        title: 'Join as breeder',
        href: JOIN_AS_BREEDER_PATH
      },
      {
        title: 'About our breeders',
        href: BREEDER_QUALITY_PATH
      },
      {
        title: 'Health testing levels',
        href: HEALTH_TESTING_GUIDE_PATH
      },
      {
        title: 'Breeder Code of Ethics',
        href: COE_BREEDER_PATH
      },
      {
        title: 'Good Breeder Center',
        href: BREEDER_CENTER_PATH
      },
      {
        title: 'The Good Dog Pod',
        href: GOOD_DOG_POD_PATH
      }
    ]
  },
  {
    name: 'buyers',
    title: 'Find a dog',
    links: [
      {
        title: 'Join to find a dog',
        href: '/#direct_signup=signup'
      },
      {
        title: 'Learning Center',
        href: LEARNING_CENTER_PATH
      },
      {
        title: 'Standards and screening',
        href: STANDARDS_PATH
      },
      {
        title: 'Dog owner policies',
        href: COE_PARENT_PATH
      }
    ]
  },
  {
    name: 'shelters',
    title: 'For shelters',
    links: [
      {
        title: 'Join',
        href: JOIN_AS_SHELTER_PATH
      },
      {
        title: 'Good Shelters & Rescues',
        href: SHELTER_QUALITY_PATH
      },
      {
        title: 'Shelter Code of Ethics',
        href: COE_SHELTER_PATH
      }
    ]
  },
  {
    name: 'legal',
    title: 'Terms & Privacy',
    links: [
      {
        title: 'Terms of Service',
        href: TOS_PATH
      },
      {
        title: 'Privacy',
        href: PRIVACY_PATH
      }
    ]
  },
  {
    name: 'directory',
    title: 'Directory',
    links: [
      {
        title: 'Breeds',
        href: BREED_DIRECTORY_PATH
      },
      {
        title: 'Puppies',
        href: PUPPY_DIRECTORY_PATH
      },
      {
        title: 'Breeders',
        href: BREEDER_DIRECTORY_PATH
      }
    ]
  }
];

export default function GenericMobileHeaderDropdownContent({ isLoggedIn }) {
  const dispatch = useDispatch();

  const [shownSection, setShownSection] = useState(null);

  function handleSectionClick(sectionName) {
    setShownSection(sectionName);
  }
  function handleSectionClose() {
    setShownSection(null);
  }

  function handleBuyerSignUp() {
    dispatch(
      pushParams(
        {
          direct_signup: 'sign-up'
        },
        { hash: true }
      )
    );
  }

  return (
    <div className="GenericMobileHeaderDropdownContent">
      {!isLoggedIn && (
        <div className="bt b--light-gray">
          <GenericMobileHeaderDropdownContentSubNav
            href={LOGIN_PATH}
            name="login"
            title="Log in"
          />
          <GenericMobileHeaderDropdownContentSubNav
            href={JOIN_AS_BREEDER_PATH}
            name="breeder-signup"
            title="Join as breeder"
          />
          <GenericMobileHeaderDropdownContentSubNav
            href={JOIN_AS_SHELTER_PATH}
            name="shelter-signup"
            title="Join as shelter or rescue"
          />
          <GenericMobileHeaderDropdownContentSubNav
            onOpen={handleBuyerSignUp}
            name="buyer-signup"
            title="Join to find a dog"
            hideOpenArrow
          />
        </div>
      )}
      {SECTIONS.map(({ href, links, name, title }, idx) => (
        <GenericMobileHeaderDropdownContentSubNav
          href={href}
          isOpen={shownSection === name}
          key={idx}
          links={links}
          name={name}
          onClose={handleSectionClose}
          onOpen={links ? () => handleSectionClick(name) : null}
          title={title}
        />
      ))}
    </div>
  );
}

GenericMobileHeaderDropdownContent.propTypes = {
  isLoggedIn: PropTypes.bool
};

GenericMobileHeaderDropdownContent.defaultProps = {
  isLoggedIn: false
};

export function GenericMobileHeaderDropdownContentSubNav({
  href,
  isOpen,
  links,
  name,
  onClose,
  onOpen,
  title,
  hideOpenArrow
}) {
  return (
    <Fragment>
      <a
        className="pv5 bb b--black-10 db flex items-center"
        href={href}
        key={name}
        onClick={
          onOpen &&
          (e => {
            e.preventDefault();
            onOpen();
          })
        }
      >
        <span className="flex-auto fw-medium">{title}</span>
        {onOpen && !hideOpenArrow && <Icon name="fat-arrow-right" />}
      </a>
      {links && links.length > 0 && (
        <MobileMenu isOpen={isOpen} position="right">
          <div className="pv5 ph4">
            <button
              className="flex items-center black-50 fw-medium"
              onClick={onClose}
              type="button"
            >
              <Icon className="mr2" name="lined-arrow-left" />
              <span>{title}</span>
            </button>
            <ul>
              {links.map(({ href: linkHref, title: linkTitle }, idx) => (
                <li key={idx}>
                  <a
                    className={classnames(
                      'pv5 bb b--black-10 db flex items-center fw-medium',
                      { bw0: idx === links.length - 1 }
                    )}
                    href={linkHref}
                  >
                    {linkTitle}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </MobileMenu>
      )}
    </Fragment>
  );
}

GenericMobileHeaderDropdownContentSubNav.propTypes = {
  href: PropTypes.string,
  isOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  links: PropTypes.array,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  title: PropTypes.string.isRequired,
  hideOpenArrow: PropTypes.bool
};

GenericMobileHeaderDropdownContentSubNav.defaultProps = {
  href: null,
  isOpen: false,
  links: null,
  onClose: null,
  onOpen: null,
  hideOpenArrow: false
};
